import React, { FC } from 'react'

import { Routes, Route } from 'react-router-dom'

import MainPage from 'pages/main'
import NotFound from 'pages/notFound'

const RoutesComponent: FC = () => (
  <Routes>
    <Route path="/" element={<MainPage />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default React.memo(RoutesComponent)
