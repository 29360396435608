import React, { FC } from 'react'

import AppleLogin from 'react-apple-login'

import appleSignIn from 'assets/images/common/appleSignIn.svg'

import style from './style.module.scss'

// TODO add clientId, redirectURI
const AppleLoginComponent: FC = () => {
  return (
    <AppleLogin
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className={style.signInBtn}
        >
          <img src={appleSignIn} alt="Apple" />
        </button>
      )}
      clientId={''}
      redirectURI={''}
    />
  )
}

export default React.memo(AppleLoginComponent)
