import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEng from './locales/en/index'
import translationEsp from './locales/es/index'

const resources = {
  en: {
    translation: translationEng,
  },
  es: {
    translation: translationEsp,
  },
}

// in future get from localStorage
const language = 'en'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
