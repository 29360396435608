import React, { FC, useState } from 'react'

import { Link } from 'react-router-dom'
import SwiperType, { A11y, Virtual, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import pyramid from 'assets/images/main/pyramid.png'
import { CAROUSEL_SIDES } from 'constants/carousel'

import 'swiper/scss'
import './style.scss'

// TODO get slides from api
const slides = [
  {
    path: '/',
    text: 'Body',
    imgPath: pyramid,
    id: 1,
  },
  {
    path: '/',
    text: 'Hair',
    imgPath: pyramid,
    id: 2,
  },
  {
    path: '/',
    text: 'Ear',
    imgPath: pyramid,
    id: 3,
  },
  {
    path: '/',
    text: 'Body',
    imgPath: pyramid,
    id: 4,
  },
  {
    path: '/',
    text: 'Hair',
    imgPath: pyramid,
    id: 5,
  },
  {
    path: '/',
    text: 'Ear',
    imgPath: pyramid,
    id: 6,
  },
  {
    path: '/',
    text: 'Eye',
    imgPath: pyramid,
    id: 7,
  },
  {
    path: '/',
    text: 'Body',
    imgPath: pyramid,
    id: 8,
  },
  {
    path: '/',
    text: 'Eye',
    imgPath: pyramid,
    id: 9,
  },
  {
    path: '/',
    text: 'Body',
    imgPath: pyramid,
    id: 10,
  },
]

const MainCarousel: FC = () => {
  const [swiper, setSwiper] = useState<SwiperType>()

  const handleChangeSlide = (side: string) => () => {
    if (side === CAROUSEL_SIDES.LEFT) {
      swiper?.slidePrev()
    } else {
      swiper?.slidePrev()
    }
  }

  return (
    <div className="carousel">
      <span
        // className={`carousel-button-prev ${swiper?.isBeginning ? "disabled" : ""}`}
        className="carousel-button-prev"
        onClick={handleChangeSlide(CAROUSEL_SIDES.LEFT)}
      ></span>

      <Swiper
        virtual
        spaceBetween={10}
        slidesPerView={6}
        modules={[Virtual, A11y, Controller]}
        breakpoints={{
          1920: { slidesPerView: 6 },
          1600: { slidesPerView: 5 },
          1024: { slidesPerView: 4 },
          600: { slidesPerView: 3 },
          440: { slidesPerView: 2 },
          320: { slidesPerView: 1 },
        }}
        controller={{ control: swiper }}
        // onSlideChange={(s) => { setSwiper(s) }}
        onSwiper={setSwiper}
        className={'swiper-container'}
      >
        {slides.map(({ id, path, imgPath, text }) => (
          <SwiperSlide key={id}>
            <Link to={path}>
              <div className={'card'}>
                <img src={imgPath} alt={text} />
                <p>{text}</p>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>

      <span
        // className={`carousel-button-next ${swiper?.isEnd ? "disabled" : ""}`}
        className="carousel-button-next"
        onClick={handleChangeSlide(CAROUSEL_SIDES.RIGHT)}
      ></span>
    </div>
  )
}

export default React.memo(MainCarousel)
