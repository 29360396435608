import React, { FC } from 'react'

import FacebookLogin from 'react-facebook-login'

import facebookLoginIcon from 'assets/images/common/facebookSignIn.svg'

import style from './style.module.scss'

// TODO add appId, redirectUri
const FacebookLoginComponent: FC = () => {
  return (
    <FacebookLogin
      callback={() => ''}
      appId={''}
      textButton={''}
      redirectUri={''}
      icon={
        <img width={26} height={26} src={facebookLoginIcon} alt="facebook" />
      }
      cssClass={style.signInBtn}
    />
  )
}

export default React.memo(FacebookLoginComponent)
