import React, { useState, ChangeEvent, FC } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input, Checkbox, Select } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Trans, useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'

import { IMainSignInFormProps } from 'ts/interfaces/IMainPage'

import PasswordInput from '../../../../../../components/PasswordInput'
import style from './style.module.scss'

const INITIAL_STATE = {
  fullName: '',
  email: '',
  phoneNumberPrefix: '',
  phoneNumber: '',
  password: '',
  accessPolicy: true,
}

// TODO errors
// TODO style phone number errors
const RegistrationForm: FC<IMainSignInFormProps> = ({ onSave }) => {
  const { t } = useTranslation()
  const [fields, setFields] = useState(INITIAL_STATE)

  const handleChecked = (
    event: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent,
  ) => {
    const { name, checked } = event.target

    setFields((prevState) => ({
      ...prevState,
      [String(name)]: checked,
    }))
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent,
  ) => {
    const { name, value } = event.target

    setFields((prevState) => ({
      ...prevState,
      [String(name)]: value,
    }))
  }

  const handleSelect = (name: string) => (value: string) =>
    setFields((prevState) => ({ ...prevState, [name]: value }))

  return (
    <Form layout={'vertical'} className={style.registerForm} size={'large'}>
      <Form.Item label={t('common.fullName')}>
        <Input
          type={'text'}
          name={'fullName'}
          value={fields.fullName}
          onChange={handleChange}
          placeholder={t('common.fullName')}
        />
      </Form.Item>

      <Form.Item label={t('common.email')}>
        <Input
          type={'email'}
          name={'email'}
          value={fields.email}
          onChange={handleChange}
          placeholder={t('common.email')}
        />
      </Form.Item>

      <Form.Item
        label={t('common.phoneNumber')}
        rules={[
          {
            required: true,
            message: t('main.error.phone'),
          },
        ]}
        className={style.phoneWrapper}
      >
        <Select
          style={{
            width: 70,
          }}
          onSelect={handleSelect('phoneNumberPrefix')}
          value={fields.phoneNumberPrefix}
        >
          <Select.Option value="1">+1</Select.Option>
          <Select.Option value="7">+7</Select.Option>
          <Select.Option value="8">+8</Select.Option>
        </Select>

        {/* TODO errors */}
        <InputMask
          name={'phoneNumber'}
          mask={'999-999-99-99'}
          placeholder={'___-___-__-__'}
          onChange={handleChange}
          value={fields.phoneNumber}
          className={style.phoneNumber}
        />
      </Form.Item>

      <PasswordInput
        label={t('common.password')}
        tooltip={{
          title: 'Info',
          icon: (
            <div>
              <InfoCircleOutlined />
            </div>
          ),
        }}
        name={'password'}
        value={fields.password}
        onChange={handleChange}
        placeholder={t('common.password')}
      />

      <Form.Item>
        <Checkbox
          name={'accessPolicy'}
          onChange={handleChecked}
          checked={fields.accessPolicy}
        >
          <Trans
            t={t}
            i18nKey={'main.registerCheckboxLabel'}
            components={[
              <Link className={style.policyLink} to="/terms-and-conditions" />,
              <Link className={style.policyLink} to="/privacy-policy" />,
            ]}
          />
        </Checkbox>
      </Form.Item>

      <button className={style.loginBtnWrapper} onClick={onSave(fields)}>
        {t('common.signIn')}
      </button>

      <div className={style.footnote}>
        <Trans
          t={t}
          i18nKey={'main.registerFootnote'}
          components={[
            <Link className={style.personalAccount} to="/sign-in" />,
          ]}
        />
      </div>
    </Form>
  )
}

export default React.memo(RegistrationForm)
