import React, { FC } from 'react'

import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import facebook from 'assets/images/social/facebook.svg'
import instagram from 'assets/images/social/instagram.svg'

import style from './style.module.scss'

const MainFooter: FC = () => {
  const { t } = useTranslation()

  return (
    <footer className={style.mainFooter}>
      <div className={style.mainFooterWrapper}>
        <Row className={style.mainFooterNavLink}>
          <Col>
            <nav className={style.mainFooterNavigation}>
              <Link to="/">{t('main.FAQ')}</Link>
              <Link to="/">{t('main.aboutUs')}</Link>
              <Link to="/">{t('main.contactUs')}</Link>
              <Link to="/">{t('main.forBussinessOwners')}</Link>
              <Link to="/">{t('main.forCustomers')}</Link>
              <Link to="/">{t('main.privacyPolicy')}</Link>
              <Link to="/">{t('main.termsAndConditions')}</Link>
            </nav>
          </Col>

          <Col className={style.mainFooterSocialLinks}>
            <Link to="/">
              <img src={instagram} alt={t('common.instagram')} />
            </Link>
            <Link to="/">
              <img src={facebook} alt={t('common.facebook')} />
            </Link>
          </Col>
        </Row>

        <Row className={style.mainFooterText}>
          <Col>
            <p>© 2014 BMethod</p>
          </Col>
          <Col>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus
              nesciunt, commodi nam totam harum molestiae explicabo omnis error
              facere alias molestias odit nihil veritatis, consequuntur fugit,
              velit iure. Voluptas, id.
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  )
}

export default React.memo(MainFooter)
