import React, { FC } from 'react'

import RoutesComponent from './components/Routes'

const App: FC = () => {
  return (
    <div className="App">
      <RoutesComponent />
    </div>
  )
}

export default App
