import React, { useState, ChangeEvent, FC } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IMainSignInFormProps } from 'ts/interfaces/IMainPage'

import PasswordInput from '../../../../../../components/PasswordInput'
import style from './style.module.scss'

const INITIAL_STATE = {
  email: '',
  password: '',
  remember: false,
}

// TODO errors
const SignInForm: FC<IMainSignInFormProps> = ({ onSave }) => {
  const { t } = useTranslation()
  const [fields, setFields] = useState(INITIAL_STATE)

  const handleChecked = (
    event: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent,
  ) => {
    const { name, checked } = event.target

    setFields((prevState) => ({
      ...prevState,
      [String(name)]: checked,
    }))
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent,
  ) => {
    const { name, value } = event.target

    setFields((prevState) => ({
      ...prevState,
      [String(name)]: value,
    }))
  }

  return (
    <Form layout={'vertical'} className={style.loginForm} size={'large'}>
      <Form.Item label={t('common.email')}>
        <Input
          type={'email'}
          name={'email'}
          value={fields.email}
          onChange={handleChange}
          placeholder={t('common.email')}
        />
      </Form.Item>

      <PasswordInput
        label={t('common.password')}
        tooltip={{
          title: 'Info',
          icon: (
            <div>
              <InfoCircleOutlined />
            </div>
          ),
        }}
        name={'password'}
        className={style.signInPasswordField}
        value={fields.password}
        onChange={handleChange}
        placeholder={t('common.password')}
        extra={
          <div className="text-right">
            <Link to={'/forgot-password'}>{t('common.forgotPassword')}</Link>
          </div>
        }
      />

      <Form.Item>
        <Checkbox
          name={'remember'}
          onChange={handleChecked}
          checked={fields.remember}
        >
          {t('common.rememberMe')}
        </Checkbox>
      </Form.Item>

      <button onClick={onSave(fields)}>{t('common.logIn')}</button>
    </Form>
  )
}

export default React.memo(SignInForm)
