import React, { useState, FC } from 'react'

import { Input } from 'antd'
import { useTranslation } from 'react-i18next'

import cactus from 'assets/images/main/cactus.png'
import bgexample from 'assets/images/main/examplebg.png'
import ocean from 'assets/images/main/ocean.png'
import pyramid from 'assets/images/main/pyramid.png'
import bgexample2 from 'assets/images/main/waveBg.svg'

import MainCarousel from './_elements/MainCarousel'
import MainFooter from './_elements/MainFooter'
import MainHeader from './_elements/MainHeader'
import SignInDrawer from './_elements/SignInDrawer'
import styles from './style.module.scss'

const MainPage: FC = () => {
  const { t } = useTranslation()
  const [isShowSignIn, setShowSignIn] = useState(false)

  const handleToggleSignIn = () => setShowSignIn((prevState) => !prevState)

  return (
    <>
      <MainHeader onOpenSignIn={handleToggleSignIn} />

      <SignInDrawer isShow={isShowSignIn} onClose={handleToggleSignIn} />

      {/* TODO in future all text & pic from api */}
      {/* TODO add skeleton */}
      {/* TODO inputs */}
      <main>
        <section className={styles.sectionOne}>
          <div
            className={styles.sectionOnePicWrapper}
            style={{ backgroundImage: `url(${bgexample})` }}
          >
            <h1>Awaken to a healthier, happier you</h1>
            <p>Discover and book beauty & wellness professional near you</p>

            {/* TODO create inputs */}
            <Input.Group compact></Input.Group>
          </div>
        </section>

        <section className={styles.sectionTwo}>
          <h2>Categories</h2>

          <p>
            Managing a salon can be easy, pleasant and stress-free. Create an
            efficient and motivated team.
          </p>

          <MainCarousel />
        </section>

        <section className={styles.sectionThree}>
          {/* TODO img size */}
          <img src={ocean} alt="ocean" />

          <div className={styles.sectionThreeDescription}>
            <h6>
              Dedicated solutions for organisations with high-level demands
              around performance, security, data management and existing
              infrastructure.
            </h6>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>

            <h6>
              Dedicated solutions for organisations with high-level demands
              around performance, security, data management and existing
              infrastructure.
            </h6>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>

            <h6>
              Dedicated solutions for organisations with high-level demands
              around performance, security, data management and existing
              infrastructure.
            </h6>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
            <p>
              Our software’s extensive functionality and flexibility serves use
              cases both basic and complex, regardless of the industry or
              business size. For special requirements beyond our standard
              features, we can expand functionality via our API or develop
              custom solutions.
            </p>
          </div>
        </section>

        <section className={styles.sectionFour}>
          <div className={styles.sectionFourDescription}>
            <h2>Save your time</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque
              nostrum cumque perferendis deserunt mollitia, magnam quis
              inventore quam sed similique saepe sint nemo porro quia facilis
              quod, excepturi doloribus repellat.
            </p>
          </div>

          {/* TODO fix this img sizing */}
          <div className={styles.sectionFourPics}>
            <img src={ocean} alt={'ocean'} />
            <img src={pyramid} alt={'pyramid'} />
          </div>
        </section>

        <section className={styles.sectionFive}>
          <div className={styles.sectionFiveDescription}>
            <h2>Save your time</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque
              nostrum cumque perferendis deserunt mollitia, magnam quis
              inventore quam sed similique saepe sint nemo porro quia facilis
              quod, excepturi doloribus repellat.
            </p>
          </div>

          {/* TODO fix this img sizing */}
          <div className={styles.sectionFivePics}>
            <img src={cactus} alt={'cactus'} />
            <img src={pyramid} alt={'pyramid'} />
            <img src={ocean} alt={'ocean'} />
          </div>
        </section>

        <section className={styles.sectionSix}>
          <div
            className={styles.sectionSixPicWrapper}
            style={{ backgroundImage: `url(${bgexample2})` }}
          >
            <div>
              <h2>{t('main.bringYourOwnBeautyBusinessOnline')}</h2>
              <button>{t('main.forBussinessOwners')}</button>
            </div>

            <div className={styles.sectionSixDescription}>
              <h5>{t('main.ownABeatyBusiness')}</h5>

              {/* TODO map */}
              <ul>
                <li>{t('main.automaticAppointmentsReminders')}</li>
                <li>{t('main.customersHistoryAndDatabase')}</li>
                <li>{t('main.scheduledServiceBooking')}</li>
                <li>{t('main.customisations')}</li>
                <li>{t('main.locationManagment')}</li>
              </ul>
            </div>
          </div>
        </section>
      </main>

      <MainFooter />
    </>
  )
}

export default React.memo(MainPage)
