import React, { FC, useState } from 'react'

import { Drawer, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import closeIcon from 'assets/images/common/close.svg'
import { CUSTOMER_SIGN_IN_TYPE } from 'constants/customerSignIn'
import { IMainSignInDrawer } from 'ts/interfaces/IMainPage'

import RegistrationCongratulations from './_elements/RegistrationCongratulations'
import SignInBody from './_elements/SignInBody'

import './style.scss'

const SignInDrawer: FC<IMainSignInDrawer> = ({ isShow, onClose }) => {
  const { t } = useTranslation()
  const [isRegistered, setRegistered] = useState(false)

  return (
    <Drawer
      closable={false}
      placement="right"
      visible={isShow}
      onClose={onClose}
      width={664}
    >
      {isRegistered && (
        <RegistrationCongratulations
          setRegistered={setRegistered}
          onClose={onClose}
        />
      )}
      {!isRegistered && (
        <>
          <div className={'sign-in-close'}>
            <img src={closeIcon} alt={'close'} onClick={onClose} />
          </div>

          <div className={'sign-in-wrapper'}>
            <Tabs defaultActiveKey="1" size={'large'} tabBarGutter={127}>
              <Tabs.TabPane tab={t('common.logIn')} key="1">
                <SignInBody type={CUSTOMER_SIGN_IN_TYPE.SIGN_IN} />
              </Tabs.TabPane>

              <Tabs.TabPane tab={t('common.registration')} key="2">
                <SignInBody type={CUSTOMER_SIGN_IN_TYPE.REGISTRATION} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Drawer>
  )
}

export default React.memo(SignInDrawer)
