import React, { FC } from 'react'

import { Form, Input, FormItemProps, InputProps } from 'antd'
import { useTranslation } from 'react-i18next'

import eyeClosed from '../../assets/images/common/eye-closed.svg'
import eyeOpen from '../../assets/images/common/eye-open.svg'

export interface PasswordProps extends InputProps {
  readonly action?: string
  iconRender?: (visible: boolean) => React.ReactNode
  readonly inputPrefixCls?: string
  visibilityToggle?: boolean
}

export interface PasswordInputProps
  extends Omit<FormItemProps, 'children' | 'name' | 'onReset' | 'status'>,
    PasswordProps {}

const PasswordInput: FC<PasswordInputProps> = ({
  id,
  name,
  label,
  extra,
  style,
  value,
  hidden,
  required,
  onChange,
  className,
  placeholder,
  validateStatus,
  tooltip = null,
  labelAlign = 'left',
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      id={id}
      label={label}
      extra={extra}
      style={style}
      hidden={hidden}
      tooltip={tooltip}
      required={required}
      className={className}
      labelAlign={labelAlign}
      validateStatus={validateStatus}
    >
      <Input.Password
        {...props}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        iconRender={(visible) => (
          <img
            width={16}
            height={16}
            src={visible ? eyeOpen : eyeClosed}
            style={{ cursor: 'pointer' }}
            alt={visible ? t('common.show') : t('common.hide')}
          />
        )}
      />
    </Form.Item>
  )
}

export default React.memo(PasswordInput)
