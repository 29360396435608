import React, { FC } from 'react'

import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import logo from 'assets/images/logo/logo.svg'
import favorites from 'assets/images/main/favorites.svg'
import { IMainHeaderProps } from 'ts/interfaces/IMainPage'

import styles from './style.module.scss'

const MainHeader: FC<IMainHeaderProps> = ({ onOpenSignIn }) => {
  const { t } = useTranslation()

  return (
    <header className={styles.mainHeader}>
      <Row className={styles.row}>
        <Col>
          <Link to="/">
            <img src={logo} alt={t('main.logo')} />
          </Link>
        </Col>

        <Col className={styles.mainHeaderActions}>
          <Link to="/favorites">
            <img src={favorites} alt={t('main.favorites')} />
          </Link>

          <button className={styles.mainOwnerBtn}>
            {t('main.forBussinessOwners')}
          </button>
          <button className={styles.logIn} onClick={onOpenSignIn}>
            {t('common.logIn')}
          </button>
        </Col>
      </Row>
    </header>
  )
}

export default React.memo(MainHeader)
