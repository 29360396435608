import React, { FC } from 'react'

import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { IMainRegistrationCongratulations } from 'ts/interfaces/IMainPage'

import successIcon from '../../../../../../assets/images/common/success.svg'
import style from './style.module.scss'

const RegistrationCongratulations: FC<IMainRegistrationCongratulations> = ({
  setRegistered,
  onClose,
}) => {
  const { t } = useTranslation()

  const handleDone = () => {
    setRegistered(false)
    onClose()
  }

  return (
    <Row className={style.registrationCongratulations}>
      <Col>
        <img src={successIcon} alt={t('common.success')} />
      </Col>

      <Col>
        <h2>{t('main.thankForJoining')}</h2>
        <p>{t('main.verificationText')}</p>
        <button onClick={handleDone}>{t('common.done')}</button>
      </Col>
    </Row>
  )
}

export default React.memo(RegistrationCongratulations)
