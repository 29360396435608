import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import AppleLoginComponent from 'components/serviceIntagration/AppleLoginComponent'
import FacebookLoginComponent from 'components/serviceIntagration/FacebookLoginComponent'
import GoogleLoginComponent from 'components/serviceIntagration/GoogleLoginComponent'
import { CUSTOMER_SIGN_IN_TYPE } from 'constants/customerSignIn'
import { IMainSignInBodyProps } from 'ts/interfaces/IMainPage'

import RegistrationForm from './RegistrationForm'
import SignInForm from './SignInForm'
import style from './style.module.scss'

// TODO add some params at google, apple, facebook components
// TODO validation
// TODO intagrate with api
const SignInBody: FC<IMainSignInBodyProps> = ({ type }) => {
  const { t } = useTranslation()

  function handleSave<T>(fields: T): () => void {
    return () => {
      console.log(fields)
    }
  }

  return (
    <div className={style.loginWrapper}>
      <div className={style.loginBtnWrapper}>
        <GoogleLoginComponent />
        <AppleLoginComponent />
        <FacebookLoginComponent />
      </div>

      <p className={style.divider}>{t('common.or')}</p>

      {type === CUSTOMER_SIGN_IN_TYPE.SIGN_IN && (
        <SignInForm onSave={handleSave} />
      )}
      {type === CUSTOMER_SIGN_IN_TYPE.REGISTRATION && (
        <RegistrationForm onSave={handleSave} />
      )}
    </div>
  )
}

export default React.memo(SignInBody)
