import React, { FC } from 'react'

import { GoogleLogin } from 'react-google-login'

import googleSignIn from 'assets/images/common/googleSignIn.svg'

import style from './style.module.scss'

// TODO add clientId, onSuccess, onFailure
const GoogleLoginComponent: FC = () => {
  return (
    <GoogleLogin
      isSignedIn
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className={style.signInBtn}
        >
          <img src={googleSignIn} alt="Google" />
        </button>
      )}
      clientId={''}
      onSuccess={() => {}}
      onFailure={() => {}}
      cookiePolicy={'single_host_origin'}
    />
  )
}

export default React.memo(GoogleLoginComponent)
